<template>
<div class="alert">
    <el-form :model="formData" :rules="rules" ref="userInfo" label-width="60px" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
            <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="deptName">
            <el-select v-model="formData.deptName" placeholder="请选择部门">
                <el-option label="销售部" value="销售部"></el-option>
                <el-option label="采购部" value="采购部"></el-option>
                <el-option label="财务部" value="财务部"></el-option>
                <el-option label="品质部" value="品质部"></el-option>
                <el-option label="产品部" value="产品部"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="mail">
            <el-input v-model="formData.mail"></el-input>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
  name: 'UserInfo',
  data: function () {
    return {
      formData: {
        name: '',
        deptName: '',
        mail: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        deptName: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mail: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.alert{
    :deep(.el-select){
        width: 100%;
    }
}
</style>
